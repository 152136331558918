import { FaFacebook, FaInstagram } from "react-icons/fa";
import './Footer.scss'
import { NavLink } from "react-router-dom";

export function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <div className='social-media-container'>
                    <p className="social-media-text">
                        Social Media:
                    </p>
                    <a href="https://www.facebook.com/people/Kollmar-Angus/100063541146206/" rel="noreferrer"
                       target="_blank"><FaFacebook/></a>
                    <a href="https://www.instagram.com/kollmarangus/?igshid=MzRlODBiNWFlZA%3D%3D" rel="noreferrer"
                       target="_blank"><FaInstagram/></a>
                </div>
                <div className='imprint-container'>
                    <NavLink to="/impressum">
                        <a>Impressum </a>
                    </NavLink>
                </div>
            </div>
        </footer>
    )
}