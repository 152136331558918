import './Logo.scss'

export function Logo() {
    return <div className="logo-container" onClick={() => { }}>
        <img className="main-logo"
            src={require('../../assets/img/Kollmar_Angus_Logo_Web.png')}
            alt="AngusLogo">
        </img>
    </div>
}

export function LogoSmall() {
    return <div className="logo-container" onClick={() => { }}>
        <img className="small-logo"
             src={require('../../assets/img/Kollmar_Angus_Logo_Web.png')}
             alt="AngusLogo">
        </img>
    </div>
}