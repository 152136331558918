import './Collapse.scss'
import { FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa";
import { useState } from "react";
import * as React from "react";

export function Collapse() {

    const [open, setOpen] = useState(false);

    return <>
        <section className="collapse">
            <button onClick={() => setOpen(!open)}>
                {open ? <FaChevronCircleUp/> : <FaChevronCircleDown/>}
                Wie kann ich Produkte bestellen?
                {open ? <FaChevronCircleUp/> : <FaChevronCircleDown/>}
            </button>
            <section className={open ? "text open" : "text closed"}>
                <p className="text-line">
                    <b>1.</b> Mit Klick auf den Download-Button laden Sie den
                    aktuellen Kollmar-Angus-Bestellschein als PDF herunter.
                </p>

                <p className="text-line">
                    <b>2.</b> Bitte geben Sie die Menge ihrer gewünschten Produkte sowie ihre persönlichen Daten an.
                </p>

                <p className="text-line">
                    <b>3.</b> Senden Sie uns den Bestellschein anschließend per Mail oder Post zu.
                </p>

                <p className="text-line">
                    <b>4.</b> Am Tag der Ausgabe müssen Sie ihr Fleisch
                    bei uns in Kaudorf abholen, außerdem erhalten Sie
                    dann eine Rechnung.
                </p>
            </section>
        </section>
    </>
}