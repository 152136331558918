import * as React from "react";
import "./Imprint.scss"
import { BackNavigation } from "../../components/BackNavigation/BackNavigation";
import { FaChevronLeft } from "react-icons/fa";

export function Imprint() {
    return <div className="imprint">
        <BackNavigation icon= {<FaChevronLeft />} />
        <h2>Impressum</h2>
        <p>Angaben gemäß § 5 TMG</p>
        <section>
            <p>Michael Kollmar</p>
            <p>Kaudorf 20</p>
            <p>91572 Bechhofen</p>
        </section>

        <section>
            <p className="big">Vertreten durch</p>
            <p>Michael Kollmar</p>
        </section>

        <section>
            <p className="big">Kontakt</p>
            <p>Telefon: 0151/58804638</p>
            <p>E-Mail: bestellungen@kollmar-angus.de</p>
        </section>
    </div>
}