import * as React from "react";
import './App.scss';
import { Routes, Route, HashRouter } from "react-router-dom";
import { StatusBanner } from "./components/StatusBanner/StatusBanner";
import { Start } from "./pages/Start/Start";
import { Footer } from "./components/Footer/Footer";
import { Imprint } from "./pages/Imprint/Imprint";


export function App() {
    return <>
        <StatusBanner possible={true}/>
        <main>
            <Routes>
                <Route path="*" element={<Start/>}/>
                <Route path="/impressum" element={<Imprint/>}/>
                {/* <Route path="/faq" element= {<Faq backButton={true}/>}/>
                <Route path="/ueber_uns" element= {<AboutUs />}/>
                <Route path="/produktliste" element= {<ProductList />}/> */}
                <Route path="/" element={<Start/>}/>
            </Routes>
            <Footer/>
        </main>
    </>
}

export default App;
