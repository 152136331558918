import * as React from "react";
import { Stage } from "../../components/Stage/Stage";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import './Start.scss'
import '../../functions/downloadPDF'
import { downloadPDF } from "../../functions/downloadPDF";
import { Collapse } from "../../components/collapse/Collapse";
import { Logo } from "../../components/Logo/Logo";

export function Start() {
    return <div className="start">
        <Logo/>

        <hr/>

        <Stage/>

        <hr/>

        <AttributesSection/>

        <hr/>

        <OrderSection/>

        <hr/>

        <ContactSection/>

    </div>
}


function AttributesSection() {
    return <>
        <section className="start-section">
            <h2>Hochwertiges Angus-Fleisch aus Mittelfranken - Direkt vom Bauernhof</h2>
            <div className="line">
                <h3>Eigene Zucht</h3>
                <p className="point">•</p>
                <h3>Weidehaltung</h3>
                <p className="point">•</p>
                <h3>Direktvermarktung</h3>
            </div>
        </section>
    </>
}

function ContactSection() {
    return <>
        <section className="start-section">
            <h3>Kontakt</h3>
            <h2>Abholung und weitere Informationen</h2>
            <div className="line">
                <h3>Michael Kollmar</h3>
                <p className="point">•</p>
                <h3>Kaudorf 20</h3>
                <p className="point">•</p>
                <h3>91572 Bechhofen</h3>
                <p className="point">•</p>
                <h3>bestellungen@kollmar-angus.de</h3>
                <p className="point">•</p>
                <h3>0151/58804638</h3>
            </div>
        </section>
    </>
}

function OrderSection() {
    return <>
        <section className="start-section">
            <h3>Bestellung</h3>
            <h2>Aktueller Bestellschein (PDF)</h2>
            <div className="button-line">
                <PrimaryButton text={"Download"} function={() => downloadPDF()}/>
            </div>
            <Collapse />
        </section>
    </>
}
