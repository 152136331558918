import * as React from "react";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../SecondaryButton/SecondaryButton";
import "./BackNavigation.scss"

export function BackNavigation (props: {icon?: any}) {
    let navigate = useNavigate();

    return <div className="back-navigation-container">
        <SecondaryButton text= "Zurück" function={() => navigate('/')} icon={props.icon}/>
    </div>
}